@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?udberh");
  src: url("../fonts/icomoon.eot?udberh#iefix") format("embedded-opentype"),
    url("../fonts/icomoon.ttf?udberh") format("truetype"),
    url("../fonts/icomoon.woff?udberh") format("woff"),
    url("../fonts/icomoon.svg?udberh#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-pending:before {
  content: "\e934";
}
.icon-bell:before {
  content: "\e933";
}

.icon-reminders .path1:before {
  content: "\e92c";
}
.icon-reminders .path2:before {
  content: "\e933";
  margin-left: -1.0625em;

}
.icon-reminders .path3:before {
  content: "\e934";
  margin-left: -1.0625em;
  color: rgb(255, 255, 255);
}
.icon-assign-kit:before {
  content: "\e935";
}
.icon-set-user:before {
  content: "\e936";
}
.icon-organization:before {
  content: "\e937";
}

.icon-fab-help:before {
  content: "\e92f";
}
.icon-fab-faq:before {
  content: "\e930";
}
.icon-fab-tour:before {
  content: "\e931";
}
.icon-fab-video:before {
  content: "\e932";
}
.icon-growth:before {
  content: "\e924";
}
.icon-lock:before {
  content: "\e925";
}
.icon-shield:before {
  content: "\e927";
}
.icon-unverified-amount:before {
  content: "\e929";
}
.icon-wallet-option:before {
  content: "\e92a";
}
.icon-Group-34430:before {
  content: "\e928";
}
.icon-expenses:before {
  content: "\e923";
}
.icon-settlements:before {
  content: "\e901";
}
.icon-policy:before {
  content: "\e91e";
}
.icon-bizpay-api:before {
  content: "\e91f";
}
.icon-zapier:before {
  content: "\e920";
}
.icon-mapping:before {
  content: "\e921";
}
.icon-expense-bucket:before {
  content: "\e922";
}
.icon-expense-type:before {
  content: "\e926";
}
.icon-flag:before {
  content: "\e91b";
}
.icon-report:before {
  content: "\e904";
}
.icon-delete:before {
  content: "\e91c";
}
.icon-edit:before {
  content: "\e91d";
}
.icon-currency:before {
  content: "\e92b";
}
.icon-mobile:before {
  content: "\e917";
}
.icon-mobile-check:before {
  content: "\e918";
}
.icon-email-check:before {
  content: "\e919";
}
.icon-email:before {
  content: "\e91a";
}
.icon-cash:before {
  content: "\e907";
}
.icon-message:before {
  content: "\e908";
}
.icon-entertainment:before {
  content: "\e90b";
}
.icon-office:before {
  content: "\e90c";
}
.icon-commute:before {
  content: "\e90d";
}
.icon-uncategorised-:before {
  content: "\e90e";
}
.icon-travel:before {
  content: "\e90f";
}
.icon-help:before {
  content: "\e92e";
}
.icon-food:before {
  content: "\e910";
}
.icon-accomodation:before {
  content: "\e911";
}
.icon-fuel:before {
  content: "\e912";
}
.icon-material:before {
  content: "\e913";
}
.icon-labour:before {
  content: "\e914";
}
.icon-time-based:before {
  content: "\e915";
}
.icon-meter:before {
  content: "\e916";
}
.icon-pie-chart:before {
  content: "\e909";
}
.icon-line-chart:before {
  content: "\e90a";
}
.icon-car:before {
  content: "\e906";
}
.icon-card:before {
  content: "\e903";
}
.icon-expense:before {
  content: "\e900";
}
.icon-home:before {
  content: "\e92c";
}
.icon-money-request:before {
  content: "\e902";
}
.icon-report-sb:before {
  content: "\e92d";
}
.icon-setting:before {
  content: "\e905";
}
