@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "./assets/theme/variable";
@import "./../node_modules/quill/dist/quill.snow.css";

@import "./../node_modules/ngx-spinner/animations/ball-scale-multiple.css";
@import "./../node_modules/bootstrap/scss/bootstrap.scss";
@import "./../node_modules/primeicons/primeicons.css";
@import "./../node_modules/font-awesome/scss/font-awesome.scss";
@import "./assets/theme/primeng.scss";
@import "./assets/theme/theme.scss";
@import "./assets/theme/global.scss";
@import "./assets/theme/css/component.scss";
@import "./assets/theme/css/vertical-menu.scss";
@import "./assets/theme/icon.scss";
@import "./../node_modules/ngx-toastr/toastr.css";
@import "./assets/theme/button.scss";

.text-mute{
    color: #828282;
    font-size: 12px;
    font-weight: 400;
  }

  .toast-container {
    position: fixed;
    z-index : 9999999 !important;
  }

  .custom-iconc{
    color:  rgba(142, 142, 169, 1);

  }

// ANGULAR 17 UI Adjust

  .p-avatar.p-avatar-circle {
    border-radius: 50%;
    overflow: hidden;
}

.p-tabview .p-tabview-nav{
  padding: 0;
}

.p-menuitem-link {
  text-decoration: none;
}

.p-calendar-w-btn .p-inputtext {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

 .p-calendar-w-btn .p-datepicker-trigger {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link{
  text-decoration: none;
}
// ANGULAR 17 UI Adjust

.card-sense-info iframe{
  height: 100%;
  position: absolute;
  margin-right: 30px;
}

.brl-0{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
