body {
  color: $primary-black;
  font-family: $font-family;
}

.card {
  box-shadow: 0px 0px 8px rgba(184, 184, 210, 0.3);
  border-radius: 8px;
  border: none;
}

// #region
.auth-wrapper {
  display: flex;
  flex-basis: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
}

.auth-wrapper .auth-inner {
  width: 100%;
  position: relative;
}

.auth-wrapper.auth-v1 {
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.auth-wrapper.auth-v1 .auth-inner:before {
  width: 244px;
  height: 243px;
  content: " ";
  position: absolute;
  top: -54px;
  left: -46px;
}

@media (max-width: 575.98px) {
  .auth-wrapper.auth-v1 .auth-inner:before {
    display: none;
  }
}

.auth-wrapper.auth-v1 .auth-inner:after {
  width: 272px;
  height: 272px;
  content: " ";
  position: absolute;
  bottom: -55px;
  right: -75px;
  z-index: -1;
}

@media (max-width: 575.98px) {
  .auth-wrapper.auth-v1 .auth-inner:after {
    display: none;
  }
}

.auth-wrapper.auth-v2 {
  align-items: flex-start;
}

.auth-wrapper.auth-v2 .auth-inner {
  height: 100vh;
  overflow-y: auto;
  height: calc(var(--vh, 1vh) * 100);
}

.auth-wrapper.auth-v2 .brand-logo {
  position: absolute;
  top: 2rem;
  left: 2rem;
  margin: 0;
  z-index: 1;
}

.auth-wrapper.auth-v1 .auth-inner {
  max-width: 400px;
}

.auth-wrapper .brand-logo {
  display: flex;
  justify-content: center;
  margin: 1rem 0 2rem 0;
}

.auth-wrapper .brand-logo .brand-text {
  font-weight: 600;
}

.auth-wrapper .auth-footer-btn .btn {
  padding: 0.6rem !important;
}

.auth-wrapper .auth-footer-btn .btn:not(:last-child) {
  margin-right: 1rem;
}

@media (min-width: 1200px) {
  .auth-wrapper.auth-v2 .auth-card {
    width: 400px;
  }
}

.auth-wrapper .auth-bg {
  background-color: white;
}

.dark-layout .auth-wrapper .auth-bg {
  background-color: #283046;
}

.auth-dark {
  background-color: $bg-dark !important;
}

.auth-label {
  font-style: 600 !important;
  font-size: 12px;
  line-height: 16px;
}

.input-group-text {
  background-color: $primary-white;
}

// #endregion

// #region

.divider {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  margin: 1rem 0;
}

.divider .divider-text {
  position: relative;
  display: inline-block;
  font-size: 11px;
  padding: 0 1rem;
}

.divider .divider-text i,
.divider .divider-text svg {
  height: 1rem;
  width: 1rem;
  font-size: 1rem;
}

.divider .divider-text:before,
.divider .divider-text:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  border-top: 1px solid #ebe9f1;
}

.divider .divider-text:before {
  right: 100%;
}

.divider .divider-text:after {
  left: 100%;
}

.divider.divider-left .divider-text {
  float: left;
  padding-left: 0;
}

.divider.divider-left .divider-text:before {
  display: none;
}

.divider.divider-left-center .divider-text {
  left: -25%;
}

.divider.divider-right .divider-text {
  float: right;
  padding-right: 0;
}

.divider.divider-right .divider-text:after {
  display: none;
}

.divider.divider-right-center .divider-text {
  right: -25%;
}

.divider.divider-dotted .divider-text:before,
.divider.divider-dotted .divider-text:after {
  border-style: dotted;
  border-width: 1px;
  border-top-width: 0;
  border-color: #ebe9f1;
}

.divider.divider-dashed .divider-text:before,
.divider.divider-dashed .divider-text:after {
  border-style: dashed;
  border-width: 1px;
  border-top-width: 0;
  border-color: #ebe9f1;
}

// #endregion

.pointer {
  cursor: pointer;
}

.ml-1 {
  margin-left: 1rem;
}

.form-group {
  margin-bottom: 1.75rem;
}

p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #666687;
}

.btn-bg-light {
  background-color: #d9d8ff !important;
}

.form-label {
  color: #32324d !important;
  font-size: 12px !important;
  line-height: 16px !important;
}

.p-checkbox-label {
  color: #32324d !important;
  font-size: 12px !important;
  line-height: 16px !important;
}

.page-header {
  font-family: $font-family;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
}

.p-button-icon {
  font-weight: 900;
}

.form-label {
  font-weight: 600;
}

.c-card-head {
  font-family: $font-family;
  font-size: 16px;
  font-weight: 700;
}

.card-header {
  background-color: #fff;
}

p-inputnumber {
  width: 100% !important;
}

.ml-auto {
  margin-left: auto;
}

// region STATUS
.status-pending {
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
  color: #C79A00;
  background: #F9EDC7;
  border-radius: 4px;
  padding: 4px;
}
.status-verified {
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
  color: #009F55 !important;
  background: rgba(0, 159, 85, 0.2) !important;
  border-radius: 4px;
  padding: 4px;
}
.status-approved {
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
  color: #416DB0;
  background: rgba(65, 109, 176, 0.12);
  border-radius: 4px;
  padding: 4px;
}

.status-rejected {
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
  color: #C43D28 !important;
  background: #EBD0CC;
  border-radius: 4px;
  padding: 4px;
}

.status-executed {
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
  color: #3D5CFF;
  background: rgba(61, 92, 255, 0.2);
  border-radius: 4px;
  padding: 4px;
}

.status-submitted {
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
  color: #E57200;
  background: rgba(229, 114, 0, 0.15);
  border-radius: 4px;
  padding: 4px;
}
.status-mr-approved {
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
  color: #0176fcb2;
  background: #deedff;
  border-radius: 4px;
  padding: 4px;
}
// endregion STATUS
// #region form validation
.invalid {
  border-color: #dc3545;
}

.valid {
  border-color: #009f55;
}

// #endregion

.otp-num-custom {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
}

.ng-otp-input-wrapper .otp-input {
  font-size: 22px !important;
  border-radius: 12px !important;
  border: 0.5px solid #b8b8d2 !important;
}

.ng-otp-input-wrapper .otp-input:not(:last-child) {
  margin-right: 28px !important;
}

@media screen and (max-width: 420px) {
  .otp-input {
    width: 48px !important;
    height: 48px !important;
  }
  
  .otp-timer{
    margin-right: 4% !important;
  }

  .ng-otp-input-wrapper .otp-input:not(:last-child) {
    margin-right: 18px !important;
  }
}