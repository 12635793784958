.btn-invert-primary {
  border-radius: 5px;
  border: 1px solid #264ef5;
  background: #fff;
  color: #264ef5;
}

.btn-invert-primary :hover {
    border-radius: 5px;
    border: 1px solid #fff;
    background: #264ef5;
    color: #fff !important;
  }
  