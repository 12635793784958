$primary-color: #134FFF;
$primary-secondary: #5325B3;
$primary-black: #191919;
$primary-white: #ffffff;
$primary-green: #27ffc3;
$primary-danger: #ff4444;
$primary: $primary-color;
$bg-dark:#1c1526;

$font-family: 'Poppins', sans-serif;
